<template>
  <div class="fullPage">
    <!-- navbar -->
    <van-nav-bar title="消息" />

    <!-- 内容 -->
    <div ref="list" style="height: calc(100vh - 96px); overflow-y: auto">
      <van-empty v-if="list.length === 0" description="" />
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click="read(item.id, index)"
        >
          <div class="left font-14">
            <div :class="item.isRead ? 'text-info' : ''">{{ item.text }}</div>
            <div class="font-12 text-info margin-t-10">
              {{ item.createdTime | date }}
            </div>
          </div>
          <div class="right d-flex" @click.stop="del(item.id, index)">
            <div class="font-16 padding-r-10 text-info">
              <van-icon name="cross" />
            </div>
          </div>
        </div>
      </van-list>
    </div>

    <!-- tabbar  -->
    <tabbar></tabbar>
  </div>
</template>

<script>
import tabbar from "@/components/TabBar";
import { listMessage, readMessage, deleteMessage } from "@/apis/water-user.js";
export default {
  name: "PageList",
  components: { tabbar },
  data() {
    return {
      active: 0,
      loading: false,
      finished: false,
      total: 0,
      list: [],
      params: {
        SkipCount: 0,
        MaxCount: 20,
      },
    };
  },
  methods: {
    onLoad() {
      this.getList();
    },
    read(id, index) {
      readMessage(id).then((res) => {
        if (res.data) {
          this.list[index].isRead = true;
        }
      });
    },
    del(id, index) {
      deleteMessage(id).then((res) => {
        if (res.data) {
          this.list.splice(index, 1);
          if (this.list.length < 20) {
            this.params.SkipCount = 0;
            this.getList();
          }
        }
      });
    },
    getList() {
      listMessage(this.params).then((res) => {
        if (res.succeeded) {
          if (this.params.SkipCount === 0) {
            this.list = res.data.items;
          } else {
            this.list.push(...res.data.items);
          }
          this.total = res.data.totalCount;
          this.loading = false;
          this.finished = false;
          if (this.list.length >= this.total) {
            this.loading = true;
            this.finished = true;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.fullPage {
  min-height: 100vh;
  min-width: 100vw;
  background-color: #f6fafd !important;
}

.item {
  margin: 15px 10px;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 5px 5px 1px #eee;
  padding: 10px;
  display: flex;
  align-items: center;

  .left {
    flex-grow: 1;
  }
}
</style>
